.coupon {
    /*border: 2px dashed #00704a;*/
}

#loader {
    /*position: absolute;*/
    /*width: 100%;*/
    /*top: 45%;*/
}

#logo {
    width: 100%;
    /*height: 120px;*/
}

#qrcode img {
    width: 100%;
    padding: 10%;
    /*height: 110px;*/
}

h3 {
    font-family: "FS Elliot Pro";
}

p {
    font-family: "FS Elliot Pro";
}

@media only screen and (max-device-width: 480px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    h3 {
        font-size: 4vw;
        font-family: "FS Elliot Pro";
    }

    p {
        font-size: 2.5vw;
        font-family: "FS Elliot Pro";
    }
}

.container2 {
    height: 100%;
    height: 100vh;
}

.errorMessage {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.clearfix {
    clear: both;
}

@font-face {
    font-family: 'FS Elliot Pro';
    src: local('/public/fonts/FS Elliot Pro-Regular.otf');
}

@font-face {
    font-family: 'FS Elliot Pro';
    src: local('/public/fonts/FS Elliot Pro-Bold.otf');
    font-weight: bold;
}

@font-face {
    font-family: 'FS Elliot Pro';
    src: local('/public/fonts/FS Elliot Pro-Italic.otf');
    font-style: italic;
}